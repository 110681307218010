.ReactFlipCard {
  margin: 25px;
  text-align: center;
}

.ReactFlipCard,
.ReactFlipCard__Front,
.ReactFlipCard__Back {
  box-sizing: border-box;
  width: 280px;
  height: 322px;
}

.ReactFlipCard__Front,
.ReactFlipCard__Back {
  border-radius: 10px;
  border: 1px solid #ccc;
}

.ReactFlipCard__Front {
  background-color: #eee;
}

.ReactFlipCard__Back {
  background-color: #eee;
}
