.fade:not(.show) {
    opacity: 1 !important;
}

.popover-title {
    background: #eee;
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
}

#popover-positioned-bottom {
    top: 100px !important;
}
